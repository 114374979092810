declare let window: any

import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core'
import Promise from 'bluebird'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Store } from 'shared-libs/models/store'

import apis from 'browser/app/models/apis'
import { Settings } from 'browser/app/models/settings'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import ComponentsMap from 'browser/components'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Card } from 'browser/components/atomic-elements/atoms/card/card'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { SheetContext } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import 'browser/components/atomic-elements/organisms/entity/entity-detail-card/_entity-detail-card.scss'
import { EntityFormSheet } from 'browser/components/atomic-elements/organisms/entity/entity-form-sheet'
import { EntityRenderer } from 'shared-libs/components/entity/renderer'
import { Entity } from 'shared-libs/models/entity'
import { ImagingSummaryModal } from 'browser/components/atomic-elements/organisms/imaging-summary-modal/imaging-summary-modal'
import { EntityFormPanel } from '../entity-form-panel'

/**
 * @uiComponent
 */
export interface IImagingDetailCardProps extends IBaseProps {
  dataSet: any
  dropdownElement: React.ReactElement<any>
  enableSchemaOverride: boolean
  entity: Entity
  header: React.ReactElement<any>
  isFullScreen?: boolean
  location: any
  navigateToNextPage?: any
  navigateToPrevPage?: any
  navigateToPage?: any
  onClose: () => void
  openOverlay: any
  printView?: string
  queries: any
  settings: Settings
}

export interface IImagingDetailCardStates {
  dataSets: any
  errors: any
  isSaving: boolean
  pageIndex: number
  previousName: string
  previousDocumentType: any
  selected: any
}

const DOCUMENT_CLASSIFICATION_TASKS_PATH = 'documentClassificationTask.classificationTasks'
const DOCUMENT_CLASSIFICATION_TASK_STATUS = 'documentClassificationTask.taskStatus'
const DOCUMENT_IMAGING_TEMPLATE_SCHEMA_URI = '/1.0/entities/metadata/documentImagingTemplate.json'

const DEFAULT_ORDERS = [
  {
    path: 'precomputation.displayName',
    type: 'ascending',
  },
]

@HotkeysTarget
class ImagingDetailCard
  extends React.Component<IImagingDetailCardProps, IImagingDetailCardStates> {

  private dataSet: EntityDataSource
  private entityRenderer: EntityRenderer
  private store: Store
  private loadInput: any

  private orderEntityType = ''
  private orderIdentifierPath = ''

  constructor(props) {
    super(props)
    this.store = apis.getStore()
    this.state = Object.assign({
      dataSets: this.createDataSets(props.queries),
      errors: null,
      isSaving: false,
      pageIndex: 0,
      previousDocumentType: null,
      previousName: null,
    })

    const { settings } = this.props

    this.orderEntityType = settings.getOrderEntityType()
    this.orderIdentifierPath = settings.getOrderIdentifierPath()
  }

  public componentDidMount() {
    const { entity } = this.props
    const { pageIndex } = this.state
    const classificationTasks = _.get(entity, 'documentClassificationTask.classificationTasks', null)
    const classificationTask = classificationTasks[pageIndex]
    const loadNumber = _.get(classificationTask, 'documentProperties.document.name', '')

    if (!_.isEmpty(this.orderEntityType)) {
      this.dataSet = new EntityDataSource({
        entityType: this.orderEntityType,
        filters: {
          path: this.orderIdentifierPath,
          type: 'match',
          value: loadNumber,
        },
        orders: DEFAULT_ORDERS,
      }).setOnChange(() => {
        this.forceUpdate()
      })
      // Need to use loadNumber to get the load entity-id
      this.dataSet.find()
    }
  }

  public componentWillUnmount() {
    const { dataSets } = this.state
    _.forEach(dataSets, (dataSource: EntityDataSource) => dataSource.dispose())
    if (this.dataSet) {
      this.dataSet.dispose()
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    // TODO(Peter): there is a bug, hence requires casting
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/14250 Problem 2
    const entity: any = this.props.entity
    const { queries } = this.props
    const { dataSets } = this.state
    if (entity !== nextProps.entity || !_.isEqual(queries, nextProps.queries)) {
      _.forEach(dataSets, (dataSource: EntityDataSource) => dataSource.dispose())
      const newDataSets = this.createDataSets(nextProps.queries)
      _.forEach(newDataSets, (dataSet: EntityDataSource) => dataSet.find())
      this.setState({ dataSets: newDataSets })
    }
  }

  public render() {
    const { className } = this.props
    return (
      <Card className={classNames('grid-block vertical w-100 relative c-entityDetailCard', className)}>
        {this.renderHelmet()}
        {this.renderHeader()}
        {this.renderSchemasForMixins()}
      </Card>
    )
  }

  public renderHotkeys() {
    const { settings } = this.props
    const imagingSettings = settings.getDocumentImagingSettings()
    const hotkeys = _.get(imagingSettings, 'documentImagingSettings.hotkeys', null)
    if (!hotkeys || _.isEmpty(hotkeys)) {
      return <Hotkeys />
    }
    return (
      <Hotkeys>
        <Hotkey
          global={true}
          group='Document Imaging'
          combo={'shift+right'}
          label={'Go To Next Task'}
          onKeyDown={this.handleNavigateToNextPage}
        />
        <Hotkey
          global={true}
          group='Document Imaging'
          combo={'shift+left'}
          label={'Go To Previous Task'}
          onKeyDown={this.handleNavigateToPreviousPage}
        />
        {_.map((hotkeys), this.renderHotkey)}
      </Hotkeys>
    )
  }

  private renderHotkey = (hotkey, index) => {
    const onkeyDown = () => this.handleChanageDocumentType(hotkey)
    return (
      <Hotkey
        key={index}
        global={true}
        group='Document Imaging'
        combo={hotkey.key}
        label={`Type to ${hotkey.documentType.displayName}`}
        onKeyDown={onkeyDown}
      />
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  private renderHelmet() {
    const { entity } = this.props
    const title = `${entity.entityType}`
    return (
      <Head title={title} />
    )
  }

  private renderHeader() {
    const { header, isFullScreen } = this.props
    return React.cloneElement(header, {
      dropdownMenuElement: this.renderDropdownMenu(),
      handleNavigateToNextPage: this.handleNavigateToNextPage,
      handleNavigateToPreviousPage: this.handleNavigateToPreviousPage,
      isFullScreen,
      relatedSalesOrders: _.take(_.map(this.dataSet && this.dataSet.content, 'data'), 1),
      onClose: this.handleOnClose,
      onTemplateCreateButtonPressed: this.handleOpenTemplateCreationSheet,
    })
  }

  private handleOpenTemplateCreationSheet = () => {
    const { entity, openOverlay } = this.props
    const documentImagingTemplateSchema = this.store.getRecord(DOCUMENT_IMAGING_TEMPLATE_SCHEMA_URI)
    const attachments = _.get(entity, 'documentClassificationTask.attachments', null)
    const associatedEntityDefaultValue = {
      documentImagingTemplate: {
        pages: {
          files: attachments,
        },
        templateType: 'Form',
      },
    }
    openOverlay(
      <EntityFormSheet
        defaultValue={associatedEntityDefaultValue}
        entitySchema={documentImagingTemplateSchema}
        onCreate={this.onTemplateCreate}
      />,
    )
  }

  private onTemplateCreate = (entity) => {
    const schemaId = _.get(entity, 'documentImagingTemplate.documentType.entityId', '')
    const { origin } = window.location
    const templateViewId = 'c714082c-b07a-40e0-8670-9086754923ff'
    const url = origin + '/settings/view/' + templateViewId + '/entity/' + schemaId + '?isFullScreen=1'
    window.open(url, '_blank')
  }

  private renderDropdownMenu() {
    const { entity, onClose, settings } = this.props

    const handleArchive = () => {
      const deleteEntity = () => {
        entity.delete().then(() => onClose ? onClose() : null)
      }
      ConfirmationModal.open({
        confirmationText: 'Do you want to delete this item?',
        confirmationTitle: 'Confirm Delete',
        modalDialogClassName: 'c-modal-dialog--sm',
        onPrimaryClicked: deleteEntity,
        primaryButtonText: 'Confirm',
      })
    }

    const handleUnArchive = () => {
      entity.undelete()
    }

    const handleDownloadOriginal = () => {
      const originalFile = _.get(entity, 'documentClassificationTask.originalFile', null)
      const win = window.open(originalFile.uri, '_blank')
      win.focus()
    }

    // const reImageEnabled = _.isEqual(_.get(entity, 'documentClassificationTask.taskStatus'), 'Queued')
    // TODO: DBGONLY
    const reImageEnabled = true
    // link it reImageEnabled, but maybe check for invalid doc type
    const autoImagingTemplateEnabled = reImageEnabled

    const handleReImage = () => {
      ImagingSummaryModal.open({
        headerTitle: 'Queued Documents',
        bodyMessage: 'The following documents will be reimaged',
        primaryButtonTitle: 'Submit',
        processingToastMessage: 'Processing ...',

        entities: [ entity ],
        relatedSalesOrders: _.take(_.map(this.dataSet && this.dataSet.content, 'data'), 1),
        getDocumentNames: ImagingSummaryModal.getClassificationTaskName,
        preSaveCallback: ImagingSummaryModal.scrubClassificationTask,

        onSave: _.noop,

        settings,
      })
    }

    return (
      <ul className='c-dropdownList'>
        {reImageEnabled && (
          <li
            className='c-dropdownList-item'
            onClick={handleReImage}
          >
            Reimage
          </li>
        )}
        {autoImagingTemplateEnabled && (
          <li
            className='c-dropdownList-item'
            onClick={this.handleOpenDocumentTemplateCreationPanel}
          >
            Create auto-imaging template
          </li>
        )}
        <li
          className='c-dropdownList-item'
          onClick={handleDownloadOriginal}
        >
          Download file
        </li>
        {this.isAdminOrFirmAdminOrManager() && (
          <li
            className='c-dropdownList-item'
            onClick={entity.isDeleted ? handleUnArchive : handleArchive}
          >
            {entity.isDeleted ? 'Un-Delete file' : 'Delete file'}
          </li>
        )}
      </ul>
    )
  }

  private handleOpenDocumentTemplateCreationPanel = () => {
    const { entity } = this.props
    const { pageIndex } = this.state
    const store = apis.getStore()
    const entitySchema = store.getRecord('/1.0/entities/metadata/textExtractTemplate.json')
    const classificationTasks = _.get(entity, 'documentClassificationTask.classificationTasks', null)
    const task = classificationTasks[pageIndex]

    const contextProps = {
      density: 'collapse',
      isHorizontalLayout: true,
    }

    const associatedEntityDefaultValue = {
      textExtractTemplate: {
        templateFile: {
          files: [task.attachment],
        }
      },
    }

    EntityFormPanel.open({
      defaultValue: associatedEntityDefaultValue,
      schema: entitySchema,
      uiContext: contextProps,
      uiSchemaPath: 'uiSchema.web.entityCreationPanel',
    })
  }

  private renderSchemasForMixins() {
    const { entity, location, settings } = this.props
    const { errors, pageIndex, previousName, previousDocumentType } = this.state
    const isEditable = this.isAdminOrFirmAdminOrManager()
    const rendererContext = {
      density: 'collapse',
      handleNavigateToNextPage: this.handleNavigateToNextPage,
      handlePageIndexChanged: this.handlePageIndexChange,
      isHorizontalLayout: true,
      isStatic: !isEditable,
      onSave: this.handleSaveEntity,
      pageIndex,
      previousDocumentType,
      previousName,
      setLoadInput: this.setLoadInput,
    }
    const handleRef = (ref) => this.entityRenderer = ref
    const renderState = Object.assign(
      {
        location,
        settings,
        relatedSalesOrders: _.take(_.map(this.dataSet && this.dataSet.content, 'data'), 1),
      },
      this.state
    )
    return (
      <EntityRenderer
        actions={this}
        componentsMap={ComponentsMap}
        context={rendererContext}
        errors={errors}
        onChangeComplete={this.handleEntityChange}
        ref={handleRef}
        state={renderState}
        value={entity}
        uiSchemaPath={'uiSchema.web.entityDetailCardBodyFullScreen'}
      />
    )
  }

  private handlePageIndexChange = (nextPageIndex) => {
    const { pageIndex } = this.state
    const { entity } = this.props
    let previousName = null
    let previousDocumentType = null
    if (pageIndex === nextPageIndex - 1) {
      const { classificationTasks } = _.get(entity, 'documentClassificationTask', null)
      const currentTask = classificationTasks[pageIndex]
      previousName = _.get(currentTask, 'documentProperties.document.name', null)
      previousDocumentType = _.get(currentTask, 'documentType', null)
    }
    this.setState({
      pageIndex: nextPageIndex,
      previousDocumentType,
      previousName,
    })
  }

  private setLoadInput = (input) => {
    this.loadInput = input
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  private handleEntityChange = (classificationTask) => {
    // query for related sales order
    const loadNumber = _.get(classificationTask, 'documentProperties.document.name')
    if (!_.isNil(this.dataSet)) {
      this.dataSet.setFilters({
        path: this.orderIdentifierPath,
        type: 'match',
        value: loadNumber,
      })
      this.dataSet.find()
    }

    // TODO(Peter): this is not great to forceUpdate when entity change
    this.forceUpdate()
  }

  private handleSaveEntity = (): Promise<any> => {
    const { navigateToNextPage } = this.props
    this.setState({ isSaving: true })
    return this.validateTaskEntityAndSave()
      .then(() => {
        this.setState({ errors: null })
        navigateToNextPage()
      })
      .catch(({ errors }) => this.setState({ errors }))
      .finally(() => this.setState({ isSaving: false }))
  }

  private handleChanageDocumentType = (hotkey) => {
    event.preventDefault()
    const { entity } = this.props
    const { pageIndex } = this.state
    const classificationTasks = _.get(entity, DOCUMENT_CLASSIFICATION_TASKS_PATH, [])
    const task = classificationTasks[pageIndex]
    _.set(task, 'documentType', hotkey.documentType)
    if (this.loadInput) {
      this.loadInput.getElement().focus()
    }
    this.forceUpdate()
  }

  private handleOnClose = () => {
    const { onClose } = this.props
    this.validateTaskEntityAndSave().then(() => onClose())
  }

  private handleNavigateToNextPage = () => {
    const { navigateToNextPage } = this.props
    this.validateTaskEntityAndSave().then(() => navigateToNextPage())
  }

  private handleNavigateToPreviousPage = () => {
    const { navigateToPrevPage } = this.props
    this.validateTaskEntityAndSave().then(() => navigateToPrevPage())
  }

  private validateTaskEntityAndSave = () => {
    const { entity } = this.props
    const taskStatus = entity.get(DOCUMENT_CLASSIFICATION_TASK_STATUS)
    // const classificationTasks = _.get(entity, DOCUMENT_CLASSIFICATION_TASKS_PATH, [])
    // const documentNameMissing = _.some(classificationTasks, (task) => {
    //   const documentName = _.get(task, 'documentProperties.document.name', null)
    //   return !task.documentType
    //     || !documentName
    //     || _.isEqual(documentName, "Document Needs Load Number")
    // })

    // don't re-set the task status if it is "Published".  When the user hits
    // "Published", it will set the status to "Published" and save the entity
    // and once this view is closed, it should not trigger any status update
    // nor save the entity
    if (taskStatus === 'Published' ) {
      return Promise.resolve()
    }

    // if (documentNameMissing) {
    //   entity.set(DOCUMENT_CLASSIFICATION_TASK_STATUS, 'Queued')
    // } else {
    //   entity.set(DOCUMENT_CLASSIFICATION_TASK_STATUS, 'Indexed')
    // }
    return entity.save()
  }

  //////////////////////////////////////////////////////////////////////////////
  // Helpers
  //////////////////////////////////////////////////////////////////////////////

  private createDataSets(dataSets) {
    const results = {}
    _.forEach(dataSets, (dataSource, key) => {
      results[key] = new EntityDataSource(dataSource)
        .setOnChange(() => this.forceUpdate())
    })
    return results
  }

  private isAdminOrFirmAdminOrManager() {
    const { settings } = this.props
    return settings.isAdmin || settings.isFirmAdmin || settings.isManager
  }
}

export default React.forwardRef((props: IImagingDetailCardProps, ref: React.Ref<ImagingDetailCard>) => (
  <AppNavigatorContext.Consumer>
    {({ settings }) => (
      <SheetContext.Consumer>
        {({ openOverlay }) => (
          <ImagingDetailCard {...props} openOverlay={openOverlay} settings={settings} ref={ref} />
        )}
      </SheetContext.Consumer>
    )}
  </AppNavigatorContext.Consumer>
))
